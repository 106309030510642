import React from 'react'
import { WrapperSection } from '../wrappers'

const Map = () => {
  return (
    <WrapperSection>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96957.58377237357!2d18.568019666830168!3d54.41107953204812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd74c4504ab699%3A0x736040621edf0ab6!2sReklamaDlaBiznesu.pl!5e0!3m2!1sen!2spl!4v1654777868177!5m2!1sen!2spl"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        css={sFrame}></iframe>
    </WrapperSection>
  )
}

const sFrame = {
  border: 'none',
  width: '100%',
  height: '100%',
  minHeight: 360,
  marginBottom: '6rem',
  borderRadius: '1.5rem',
}

export default Map
